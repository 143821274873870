import {
  Box,
  Card,
  Loader,
  Page,
  WixDesignSystemProvider,
  Button,
  Layout,
  Cell,
  Badge,
  Heading,
  EmptyState,
  TextButton,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import * as BC from "@certifiedcode/base-components";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [usageNumber, setUsageNumber] = React.useState(0);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, []);

  const BASE_URL = `https://certifiedcode.wixsite.com/custom-dashboard/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {});
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=d94fde45-38b9-4041-9094-efcbb577f318&redirectUrl=https://certifiedcode.wixsite.com/custom-dashboard/_functions/@certifiedcode/base-backend/auth`;
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={"Partner Connect"}
          subtitle={
            "Explore Solutions, Offers & News Brought by Certified Code"
          }
        />
        <Page.Content>
          <Layout>
            <Cell span={12}>
              <Card showShadow={false}>
                <Layout gap={0}>
                  <Cell span={12}>
                    <Box height="18px" marginTop={2} marginLeft={-1}>
                      <Badge size="small" skin="standard">
                        WE ARE HERE TO HELP
                      </Badge>
                    </Box>
                  </Cell>
                  <Cell>
                    <Box height="72px" padding="12px 24px 0 24px">
                      <Heading size="small">
                        {"Schedule a callback from Wing"}
                      </Heading>
                    </Box>
                  </Cell>
                  <Cell>
                    <Box padding="24px" gap="SP1">
                      <Button
                        id="connect-schedule-a-call"
                        prefixIcon={<Icons.WixBooking />}
                        size="small"
                      >
                        View my calendar
                      </Button>
                      <Button
                        prefixIcon={<Icons.Whatsapp />}
                        priority="secondary"
                        size="small"
                        as='a'
                        href='https://wa.me/13475086396'
                        target='_blank'
                      >
                        Send me an message
                      </Button>
                    </Box>
                  </Cell>
                </Layout>
              </Card>
            </Cell>
            <Cell span={12}>
              <BC.Offers appId="d94fde45-38b9-4041-9094-efcbb577f318" />
            </Cell>
            <Cell span={12}>
              <EmptyState
                theme="page"
                title="More content coming soon"
                subtitle="In the meantime, you can check out our other apps"
              >
                <TextButton
                  as="a"
                  href="https://www.wix.com/app-market/developer/certified-code"
                  target={"_blank"}
                  prefixIcon={<Icons.AppMarket />}
                >
                  View App Collections
                </TextButton>
              </EmptyState>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
